import React, {useContext, useMemo, useState} from "react";
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue, Image, Flex, Button
} from "@chakra-ui/react";
import {LOG} from "../../tools";
import TopicBadge from "./TopicBadge";
import {getUrlAnalyse, getUrlImgCard} from "../../api/getAnalyseData";
import {Link} from "gatsby";
import {ArrowForwardIcon} from "@chakra-ui/icons";


const AnalyseCard = ({jsonAnalyse})=>{

    const {title,id,url,topics,authors,date,time,description,imgname} = jsonAnalyse

  const urlImgCard = getUrlImgCard(id,imgname)
  const urlAnalyse = getUrlAnalyse(url)
    const dateObject = new Date(date)
    return <Center m={[1,2]} py={6} >
      <Flex
          minH={500}
          flexDirection={"column"}
          justifyContent={"space-between"}
        maxW={'445px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        borderStyle={"solid"} borderWidth={"1px"} borderColor={"text.50"}
        overflow={'hidden'}>
        <Box
          h={'150px'}
          bg={'gray.100'}
          mt={-6}
          mx={-6}
          mb={6}
          pos={'relative'}>
          <Image roundedTop={'md'}
          height={"100%"}
          width={"100%"}
                 opacity={0.5}
            src={urlImgCard            }
            fit={"cover"}
          />
        </Box>
        <Stack>
          <Text
            color={'primary.500'}
            textTransform={'uppercase'}
            fontWeight={800}
            fontSize={'sm'}
            letterSpacing={1.1}>
            Analyse
          </Text>
          <Heading
            color={useColorModeValue('gray.700', 'white')}
            fontSize={'2xl'}
            fontFamily={'body'}>
            {title}
          </Heading>
          <Flex>{topics.map(t=><TopicBadge nameTopic={t} />)}</Flex>
          <Text color={'gray.500'}>
            {description}
          </Text>
        </Stack>
        <Flex mt={6} justifyContent={"space-between"} alignItems={"center"} flexWrap={"wrap"}>
        <Stack width={["100%","70%"]}  direction={'row'} spacing={4} align={'center'}>
          <Avatar
            src={'/img/ncv.jpeg'}
            alt={'Author'}
          />
          <Stack direction={'column'} spacing={0} fontSize={'sm'}>
            <Text fontWeight={600}>{authors[0]}</Text>
            <Text color={'gray.500'}>{dateObject.toLocaleDateString()} · {time}min de lecture</Text>
          </Stack>

        </Stack>
          <Box width={["100%","30%"]} textAlign={"center"} > <Link
                        to={ urlAnalyse }>
          <Button textDecoration={"none"} rightIcon={<ArrowForwardIcon />} colorScheme='text' variant='outline'>
    Lire
  </Button>
          </Link></Box>

          </Flex>
      </Flex>
    </Center>
}

export default AnalyseCard