import React, {useContext, useMemo, useState} from "react";
import {Badge, Box, Flex, Stack, Text} from "@chakra-ui/react";
import {graphql, useStaticQuery} from "gatsby";
import {LOG} from "../../tools";
import AnalyseCard from "./AnalyseCard";


const AnalysesContent = ({})=>{
    const data = useStaticQuery(graphql`
    query {
      allAnalyse {
        edges {
          node {
            d {
              id
              title
              url
              description
              authors
              topics
              hashtags
              date
              time
              imgname
              }
           }
          }
      }
    }
  `)
    const allAnalyseEdges = data.allAnalyse.edges
    LOG("data",allAnalyseEdges)
    return <Box>
        <Stack direction='row' alignItems={"center"} flexWrap={"wrap"}>

        <Text  sx={{fontSize:['16px','20px'],lineHeight:[1.1,1.2],color:'text.200'}} p={[1,2,3]}>
        Une série de petites analyses pour comprendre l’actualité politique à partir de la donnée
        </Text>
        <Badge colorScheme='text' p={1} color={"white"} letterSpacing={"1px"}>Nouveauté</Badge>
        </Stack>
        <Flex flexWrap={"wrap"}>
        {allAnalyseEdges.map((edge,i)=>{
            const jsonAnalyse =  edge.node.d
            return <AnalyseCard key={i} jsonAnalyse={jsonAnalyse}/>
        })}
        </Flex>
    </Box>
}

export default AnalysesContent