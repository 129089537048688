import {getData} from "./getData";
import {S3_PUBLIC} from "../tools";


export function getAnalyseData(nameFile,idArticle,lastUpdateId){
    return getData(`analyses/${idArticle}/${nameFile}`,lastUpdateId)
}

export function getUrlImgCard(idArticle,imgname){
    const urlImg =  `${S3_PUBLIC}data/analyses/${idArticle}/${imgname}`
    return urlImg
}

export function getUrlAnalyse(urlArticle){
    return `/analyses/${urlArticle}`
}