import {Box, Tag} from "@chakra-ui/react";
import React,{useMemo} from "react";


const TopicBadge = ({nameTopic})=>{

    const text = useMemo(()=>{
        switch(nameTopic){
            case "invasion-ukraine":
                return "🇺🇦 Ukraine"
            case "twitter":
                return "🐦 Twitter"
            default:
                return ""
        }
    },[nameTopic])

    return <Tag>{text}</Tag>
}

export default  TopicBadge