import * as React from "react"

import Layout from "../components/layout"
import {Box, Container, Flex, Heading} from "@chakra-ui/react";
import AnalysesContent from "../components/analyses/global/AnalysesContent";
import {motion} from "framer-motion";
const MotionHeading = motion(Heading)

const Analyses = ({ }) => {

  return (
    <Layout title={"Les analyses du débat politique"}>
        <Container maxW="container.xl" sx={{
                margin: `10px auto`,
                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            }}>
            <Flex  width={["100%"]} m={1} mt={5} p={1} sx={{justifyContent: 'flex-start'}}>
                 <Box sx={{width:'15px', bg:'bg.900'}}/>
                <MotionHeading as={"h2"}  sx={{fontSize:'20px',lineHeight:1.4,color:'text.500'}} animate={{x:-5}}>Les Analyses</MotionHeading>
                </Flex>

        <AnalysesContent/>
        </Container>
    </Layout>
  )
}

export default Analyses
